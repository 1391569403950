.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .hero-section{
  background-image: url("../public/images/MultimeterClapmeter.png");
  background-size:cover;
  color: #fff;
  height: 30rem;
  width: 100rem;

} */
.hero-section p{
  font-size: 6rem;
  display: flex;
  justify-content: center;
  align-items: safe-center;
  align-self: flex-start;
  color:azure;

}
.hero-section h1{
  font-size: 18rem;
  display: flex;
  justify-content: center;
  align-self: center;
  color:azure;

}
h3{
  font-size: 12rem;
  display: flex;
  justify-content: left;
  /* align-self: center; */
  /* color:azure; */

}
p{
  font-size: 3rem;
  display: flex;
  justify-content: start;
  /* align-self: center; */
  color:azure;

}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}

main {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

form {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 16vh;
  /* position:absolute ; */
  /* bottom: 30px; */
  /* background-color: rgb(29, 26, 26); */
  width: 100%;
  max-width: 1200px;
  display: flex;
  font-size: 1.5rem;
}

form button {
  width: 20%;
  height:90%;
  background-color: rgb(56, 56, 143);
}


/* input[type=text] {
  width: 100%;
  padding: 0px 5px;
  margin: 0px 10px;
  box-sizing: border-box;
} */
/* form input {
  line-height: 1.2;
  width: "100%";
  height:97%;
  font-size: 1.5rem;
  background: rgb(59, 59, 59);
  color: white;
  outline: none;
  border: none;
  padding: 10px 10px;
} */

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}




ul, li {
  text-align: left;
  list-style: none;
}

/* p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
} */

.message {
  display: flex;
  align-items: center;
}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

img {
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  margin: 2px 5px;
}

.msg {
  display: flex;
  padding: 20px 10px 0 20px;
  margin: 20px;
  border-radius: 3000px;
  box-shadow: 0 0 10px rgb(164, 164, 164);
  align-items: center;
}

.sent {
  background-color: #395dff;
  color: white;
  border-top-right-radius: 1000px;
  flex-direction: row-reverse;
  padding: 20px 20px 0 10px;
  text-align: end;
  float: right;
}

.received {
  border: 1px solid lightgray;
  background-color: #FAFAFA;
  border-top-left-radius: 1000px;
  float: left;
}

.sendMsg {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 10px;
  z-index: 100;
  border-top: 1px solid rgb(206, 58, 58);
  margin-left: -5px;
  padding: 10px ;
  padding-bottom: 50px;
  background-color: #f8f1f1;
  color: WHITE;
}
::placeholder { 
  color:    rgb(231, 236, 236);
}
/* .input1{
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(236, 44, 44);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
} */

.msgs {
  margin: 110px 0;
  display: flex;
  flex-direction: column;
}


/* @media (max-width: 775px) {
  p {
    font-size: 20px;
  }
  .sent {
    padding: 10px 10px 0 10px;
  }
  .received {
    padding: 10px 10px 0 10px;
  }
  img {
    height: 35px;
    margin-top: -10px;
  }
}

@media (max-width: 500px) {
  p {
    font-size: 15px;
  }
  .sent {
    padding: 7px 7px 0 7px;
  }
  .received {
    padding: 7px 7px 0 7px;
  }
  img {
    height: 30px;
    margin-top: -7px;
  }
} */